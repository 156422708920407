import "/node_modules/simplelightbox/dist/simple-lightbox.min.css";
import "./styles.scss";

import React, { useEffect, useState } from "react";
import SimpleLightbox from "simplelightbox";
import classnames from "classnames";

import CheckmarkIcon from "assets/icons/checkmark.svg";

const mainClass = "home-gallery__daily";

const Daily = ({ items }) => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const lightbox = new SimpleLightbox(`.${mainClass}__items a`, {
      spinner: true,
    });

    return () => {
      lightbox.destroy();
    };
  }, [selected]);

  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__menu`}>
          {items?.map(({ title }, index) => (
            <div key={index}>
              <button
                onClick={() => setSelected(index)}
                className={classnames(`${mainClass}__menu__item`, {
                  [`${mainClass}__menu__item--active`]: selected === index,
                })}
              >
                <div className={`${mainClass}__menu__item__status`}>
                  {selected === index && <CheckmarkIcon />}
                </div>
                <span>{title}</span>
              </button>
            </div>
          ))}
        </div>
        <div key={selected} className={`${mainClass}__items`}>
          {items?.[selected]?.dailyGallery
            ?.map(({ sourceUrl }, index) => (
              <a
                key={index}
                href={sourceUrl}
                className={`${mainClass}__items__item`}
                style={{ backgroundImage: `url(${sourceUrl})` }}
              >
                &nbsp;
              </a>
            ))
            ?.reverse()}
        </div>
      </div>
    </div>
  );
};

export default Daily;
